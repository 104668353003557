
import { showModal } from '@/libs/modal-helper'
import modal from './add-open-request-modal.vue'

/**
 * 显示创建团队的弹出对话框
 * @param userId
 * @param createMode 为真时是创建模式,否则是加入模式
 * @returns
 */
export default function showOpenRequestModal (): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {
      }
    }, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
