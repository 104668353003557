import { DataApiResult, ApiResult } from './../libs/http-request'
import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'
import { BusinessInfo } from './business-info'
import { BusinessApplyItem } from './business-apply-item'

/**
 * 团队开通记录
 */
export interface CompanyRegistRecord extends BaseHasTimeEntity {

  // 团队id
  teamId?: number;

  // 开通时间
  regiterTime?: string;

  // 结束时间
  expirationTime?: string;

  /// 开通时间
  openingTime?: number;

  // 注册用户
  registerUser?: number;

  /// 申请状态
  status?: number;

  /// 申请状态名
  statusName?: string;

  // 汇款配置附件id
  payDocument?: number;

  // 开通devops运维
  registDevopsOperation?: boolean;

  // 开通webapi项目开发
  registProjectDevelopment?: boolean;

  /// 开通webfront项目开发
  registFrontProjectDevelopment?: boolean;

  /// 团队名称
  teamName?: string;

  /// 开通编号
  code?: string;

  /// 开通业务
  openBusiness?: OpenBusiness;

}

export interface OpenBusiness {
  operaBusiness?: BusinessInfo;
  webApiBusiness?: BusinessInfo;
  webFrontBusiness?: BusinessInfo;
}

/**
 * 团队服务请求api接口
 */
class CompanyRegistRecordApi extends DefaultApiService<CompanyRegistRecord> {
  constructor () {
    super('/api/company-regist-record')
  }

  /**
  * 取得id对应的对象的值
  * @param id 要删除记录的主键id
  */
  public getItemById (id: number): Promise<DataApiResult<CompanyRegistRecord>> {
    return this.request(`${id}`, undefined, 'GET')
  }

  /**
   *根据订单id获取申请业务项
   * @param id 要删除记录的主键id
   */
  public getApplyItemsByOrderId (id: number): Promise<DataApiResult<Array<BusinessApplyItem>>> {
    return this.requestList(
      `applyItems/${id}`, undefined,
      'GET'
    )
  }
}

export default new CompanyRegistRecordApi()
