import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'

/**
 * 团队开通记录
 */
export interface BusinessInfo extends BaseHasTimeEntity {
    /// 业务类型
    type?: number;

    /// 团队
    teamId?: number;

    /// 业务开始时间
    startTime?: string;

    /// 业务结束时间
    endTime?: string;
}

/**
 * 团队服务请求api接口
 */
class BusinessInfoApi extends DefaultApiService<BusinessInfo> {
  constructor () {
    super('/api/business-info')
  }
}

export default new BusinessInfoApi()
