import { ToolBarCodeExportAll } from '@/frame/share/table-panel'

/// 表格基本配置
const options = {}
/// 导出列定义
const columns = [
  { field: 'teamName', title: '团队名称', minWidth: 120 },
  { field: 'code', title: '业务开通编号', minWidth: 240 },
  { field: 'createTime', title: '申请时间', minWidth: 180 },
  {
    field: 'replyMessage',
    title: '开通回复',
    minWidth: 240,
    formatter: ({ row }: any) => {
      return row.replyMessage || '待收款确认'
    }
  },
  { field: 'regiterTime', title: '开通时间', minWidth: 180 },
  // { field: 'operaExpirationTime', title: 'devops结束时间', minWidth: 180 },
  // { field: 'webapiExpirationTime', title: 'webapi结束时间', minWidth: 180 },
  // { field: 'webfrontExpirationTime', title: 'webfront结束时间', minWidth: 180 },
  { slot: 'pay_document' },
  { slot: 'server_state' }
]

/// 导出工具栏定义
const toolbarConfig = {
  buttons: [
    { code: 'insert', name: '新增', icon: 'md-add' },
    { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
    { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
  ],
  refresh: true,
  export: true,
  print: true,
  zoom: true,
  custom: true,
  exportall: true
}
/**
 * 表格的打印配置
 */
const printConfig = {
  sheetName: '打印表格'
}

/**
 * 表格的导出配置
 */
const tableExport = {
// 默认选中类型
  type: 'xlsx',
  // 自定义类型
  types: ['xlsx', 'csv', 'html', 'xml', 'txt']
}

/// 导出配置
export default {
  options: options,
  columns: columns,
  toolbar: toolbarConfig,
  printConfig: printConfig,
  tableExport: tableExport
}
