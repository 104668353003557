
import { Component } from 'vue-property-decorator'
import tableConfig from './table-config'
import { PageDataApiResult, PageListData, PagerQueryBean } from '@/libs/http-request'
import BaseTablePanel from '@/frame/share/table-panel'
import _ from 'lodash'
import { PageInfo } from '@/libs/api-request'
import TablePanel from '@/frame/share/table-panel.vue'
import companyRegistRecordApi, {
  CompanyRegistRecord,
  OpenBusiness
} from '@/api/company-regist-record'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import showOpenRequestModal from './modal/add-open-request-modal'
import AttachmentFileDataApi from '@/api/maintain/attachment-filedata'
import businessInfo from '@/api/business-info'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-modifyTime'],
  dateRage: undefined
}
@Component({
  name: 'CompanyRegistRecordList',
  components: { TablePanel, ExpendSearchPanel }
})
export default class CompanyRegistRecordList extends BaseTablePanel<CompanyRegistRecord> {
  // 当前状态
  loading = false;
  // 表格定义
  tableConfig = tableConfig;
  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);

  imghref = '#';

  preview = false;

  showModal = false;

  /**
   * 页面数据
   */
  pageData: PageListData<CompanyRegistRecord> = {
    items: [],
    total: 0,
    pageSize: 10
  };

  /** 增加开通申请 */
  addOpenRequest () {
    showOpenRequestModal().then(() => {
      this.doPullDataList()
    })
  }

  loadMethod ({ row }: { row: CompanyRegistRecord }) {
    return new Promise<void>((resolve) => {
      Promise.all([businessInfo
        .query({
          page: 1,
          pageSize: 10,
          sorts: [],
          code: row.code
        }), companyRegistRecordApi.getApplyItemsByOrderId(row.id!)])

        .then((res) => {
          const openBusiness = {} as OpenBusiness
          openBusiness.operaBusiness = res[0].data?.items.find((e) => e.type === 0)
          openBusiness.webApiBusiness = res[0].data?.items.find((e) => e.type === 1)
          openBusiness.webFrontBusiness = res[0].data?.items.find((e) => e.type === 2)
          row.openBusiness = openBusiness
          row.registDevopsOperation = !!(res[1].data?.find((e) => e.businessType === 0))
          row.registProjectDevelopment = !!(res[1].data?.find((e) => e.businessType === 1))
          row.registFrontProjectDevelopment = !!(res[1].data?.find((e) => e.businessType === 2))
          resolve()
        })
    })
  }

  /**
   * 时间范围改变事件
   */
  onDateRangeChange (value: Array<string>) {
    this.queryData.datetimeRage = value
  }

  /// 数据库文件下载
  downByAttr (id: number) {
    /// 获取文件信息
    AttachmentFileDataApi.getItemById(id).then((data) => {
      const link = document.createElement('a')
      link.download = data.name || 'error'
      /// 下载文件
      AttachmentFileDataApi.fullDown(id)
        .then((res) => {
          const blob = new Blob([res.data])
          link.href = window.URL.createObjectURL(blob)
          console.log(link.href)
          this.imghref = link.href
          this.preview = true
          this.showModal = true
          // link.click()
          // window.URL.revokeObjectURL(link.href)

          this.$Message.success('下载成功')
        })
        .catch((err) => {
          this.$Message.error('下载失败' + err.message)
        })
    })
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  /**
   * 页面数据拉取
   */
  doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    this.pullDataList(pageInfo).then((response) => {
      this.pageData = response
      this.$Notice.success({ title: '成功', desc: '查询成功' })
    })
  }

  /**
   * 工具栏按钮事件
   */
  doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  onPullDataListFromServer (
    pagerQueryBean: PagerQueryBean
  ): Promise<PageDataApiResult<CompanyRegistRecord>> {
    return companyRegistRecordApi.query(pagerQueryBean)
  }
}
