
import { Component, Vue } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { getterUserInfo, dispatchUpdateUserInfo } from '@/store'
import CompanyRegistRecordApi, { CompanyRegistRecord } from '@/api/company-regist-record'
import UploadButton from '@/components/upload-button.vue'
import { Form } from 'view-design'
import { AttachmentFileData } from '@/api/maintain/attachment-filedata'
import { getShortUuid } from '@/libs/uuid'
import moment from 'moment'

@Component({
  name: 'AddOpenRequestModal',
  components: { UploadButton }
})
export default class AddOpenRequestModal extends Vue implements BaseModal {
  // 显示状态
  visiable = false;
  // 对话框状态
  loading = false;

  price={
    devops: 2,
    webapi: 0
  }

  /// 总金额
  totalPrice=2;

  formData: CompanyRegistRecord = {
    teamId: getterUserInfo().activedTeamId,
    openingTime: 1,
    registDevopsOperation: true
  };

  downLoadPath ='';

  created () {
    dispatchUpdateUserInfo()
  }

  uuid = getShortUuid();

  validateOpenService = (rule: any, value: any, callback: any) => {
    if (!this.formData.registProjectDevelopment && !this.formData.registDevopsOperation) {
      return callback(new Error('请最少选择一项需要开通的服务'))
    }
    if (this.formData.registProjectDevelopment && !this.formData.registDevopsOperation) {
      const userInfo = getterUserInfo()
      let preDevopsStartTime = moment()
      if (userInfo.operaExpirationTime && moment(userInfo.operaExpirationTime).isAfter(moment.now())) {
        preDevopsStartTime = moment(userInfo.operaExpirationTime)
      }
      let preWebApiStartTime = moment()
      if (userInfo.webApiExpirationTime && moment(userInfo.webApiExpirationTime).isAfter(moment.now())) {
        preWebApiStartTime = moment(userInfo.webApiExpirationTime)
      }
      if (preDevopsStartTime.isBefore(preWebApiStartTime.add(this.formData.openingTime, 'year'))) {
        return callback(new Error('开通webapi项目需先开通devops运维'))
      }
    }

    return callback()
  };

  copy () {
    (this as any).$clipboard(`汇款用于开通业务编号${this.uuid}`)
    this.$Message.success('复制成功')
  }

  timeChange (val: number) {
    this.formData.openingTime = val
    if (this.formData.registDevopsOperation) {
      this.totalPrice = this.formData.openingTime * this.price.devops
    } else {
      this.totalPrice = 0
    }
  }

  devopChange (val: boolean) {
    if (!this.formData.openingTime) {
      return
    }
    if (val) {
      this.totalPrice = this.totalPrice + this.formData.openingTime * this.price.devops
    } else {
      this.totalPrice = this.totalPrice - this.formData.openingTime * this.price.devops
    }
  }

  webApiChange (val: boolean) {
    if (!this.formData.openingTime) {
      return
    }
    if (val) {
      this.totalPrice = this.totalPrice + this.formData.openingTime * this.price.webapi
    } else {
      this.totalPrice = this.totalPrice - this.formData.openingTime * this.price.webapi
    }
  }

  validateOpenTime = (rule: any, value: any, callback: any) => {
    if (!this.formData.openingTime || this.formData.openingTime < 1) {
      return callback(new Error('开通时长需大于0'))
    }
    return callback()
  };

  validateRemitCert = (rule: any, value: any, callback: any) => {
    if (this.formData.payDocument) {
      return callback()
    }
    return callback(new Error('请上传付款凭证'))
  };

  rules = {
    openService: [{ validator: this.validateOpenService, trigger: 'blur' }],
    openTime: [{ validator: this.validateOpenTime, trigger: 'blur' }],
    remitCert: [{ validator: this.validateRemitCert, trigger: 'blur' }]
  };

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  /// 附件上传完成
  uploadFinish (res: AttachmentFileData) {
    this.formData.payDocument = res.id
    this.downLoadPath = `attr:${res.id}`
  }

  doSave (): void {
    const checkform = this.$refs.form as Form
    this.formData.code = this.uuid
    checkform.validate((valid) => {
      if (valid) {
        CompanyRegistRecordApi.insertItem(this.formData)
          .then(() => {
            this.$emit('onOk')
          })
          .catch((err) => {
            this.$Message.error(err.message)
          })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
